.SliderRoot {
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    touch-action: none;
    width: 200px;
    height: 20px;
}

.SliderTrack {
    background-color: var(--colors-\$overlay6);
    position: relative;
    flex-grow: 1;
    border-radius: 9999px;
    height: 5px;
}

.SliderRange {
    position: absolute;
    background-color: var(--colors-\$secondary8);
    border-radius: 9999px;
    height: 100%;
}

.SliderThumb {
    display: block;
    width: 15px;
    height: 15px;
    background-color: var(--colors-\$secondary5);
    box-shadow: 0 2px 5px var(--colors-\$overlay7);
    border-radius: 10px;
}

.SliderThumb:hover {
    background-color: var(--colors-\$secondary4);
}

.SliderThumb:focus {
    outline: none;
    box-shadow: 0 0 0 3px var(--colors-\$overlay8);
}