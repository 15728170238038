/* https://codepen.io/run-time/pen/VNRBJd */
.bubble {
    position: relative;
    font-family: sans-serif;
    font-size: 18px;
    line-height: 24px;
    width: 300px;
    background: #fff;
    border-radius: 40px;
    padding: 24px;
    text-align: center;
    color: #000;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
    transition: all 300ms ease-out;
    white-space: pre-line;
}

button.bubble {
    padding: 8px;
    width: 200px;
}

button.bubble:hover {
    scale: 1.02
}

.bubbleBottomLeft:before, .bubbleBottomLeft:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-right: 24px solid #fff;
    border-left: 12px solid transparent;
    border-top: 12px solid #fff;
    border-bottom: 20px solid transparent;
    right: 32px;
    bottom: -24px;
    grid-area: speech;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px; */
    border-style: solid;
    /* clip-path: polygon(-5% 5%, 120% 0%, 120% 120%); */
    /* border: var(--colors-hiContrast) 3px; */
    /* border-style: solid; */
    /* border-color: var(--colors-hiContrast); */
}

.bubbleBottomLeft:before {
    right: 32px;
    bottom: -24px;
}

.bubbleBottomLeft:after {
    right: 29px;
    bottom: -30px;
    border-right: 24px solid #000;
    border-left: 14px solid transparent;
    border-top: 12px solid #000;
    border-bottom: 20px solid transparent;
    z-index: -1;
}

.nextButton {
    content: "next";
    position: absolute;
    bottom: 6px;
    right: 45px;
    background-color: aliceblue;
    border: none;
    border-radius: 5px;
    box-shadow: #b3b3b3 0px 0px 0px 1px;
    font-size: 0.7em;
}